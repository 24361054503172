import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllowedAgent } from '../../ContactCenter/Api/api'

const AC_VIEW = 'AC_VIEW'
const WIDGETS = ['SCIP', 'CCM']

const useAllowedAgent = (widget: string) => {
  const { currentConversationAC, currentView, currentConversation } = useSelector((state: any) => state.contactCenter)
  const { agentDetails: { ldap = '' } = {} } = useSelector((state: any) => state.preferences)

  const [allowedAgentData, setAllowedAgentData] = useState({})
  const [chatInitialContactId, setChatInitialContactId] = useState('')

  const contactCenterData: any = currentView === AC_VIEW ? currentConversationAC : currentConversation

  const {
    ocPlatformData: { chatInfo: { initialContactId = '' } = {}, callInfo: { initialContactId: callInitialContactId = '' } = {} } = {},
    jcAuthData: { linkedConversationId: { value: linkedConversationId = '' } = {} } = {}
  } = contactCenterData

  useEffect(() => {
    setChatInitialContactId(initialContactId)
  }, [initialContactId])

  useEffect(() => {
    const fetchAllowedAgentDetails = async () => {
      // If the contact is in current conversation tab, set the allowed agent to TRUE
      if (currentView !== AC_VIEW || !WIDGETS.includes(widget)) {
        setAllowedAgentData({ isAllowedAgent: true, isSupportAgent: true, isSalesAgent: true })
        return
      }

      const chatId = chatInitialContactId
      const linkedContactId = linkedConversationId === chatInitialContactId ? callInitialContactId : linkedConversationId

      try {
        const allowedAgentResponse: any = await getAllowedAgent(chatId, ldap, linkedContactId)
        const agentData = allowedAgentResponse?.data?.json?.agentData

        if (agentData) setAllowedAgentData(agentData)
        else setAllowedAgentData({ isAllowedAgent: false, isSupportAgent: false, isSalesAgent: false, isEntAgent: false, isZendeskAgent: false })
      } catch (error) {
        // Handle error here if needed
        console.error('Error fetching allowed agent data:', error)
      }
    }

    // Call the fetchAllowedAgentDetails function
    fetchAllowedAgentDetails()
  }, [chatInitialContactId])

  return allowedAgentData
}

export default useAllowedAgent
