import React, { useState } from 'react'
import { BrowserRouter as Router, Route, useHistory, useLocation } from 'react-router-dom'
import { darkTheme, DialogContainer, lightTheme, Provider as V3Provider, ProgressCircle, AlertDialog } from '@adobe/react-spectrum'
import { Provider as ReduxProvider, useSelector, useDispatch } from 'react-redux'
import Provider from '@react/react-spectrum/Provider'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react'
import Chat from './Apps/Chat/Chat'
import Phone from './Apps/Phone'
import AllConversations from './Apps/Chat/Modules/AllConversations/AllConversations'
import { NavigationMenu } from './Layout'
import NetworkNotification from './Layout/Notification/NetworkNotification'
import store from './redux/store'
import './Theme/Styles/styles.scss'
import Login from './Layout/Login/Login'
import oktaconfig from './okta-stage-config'
import Profile from './Profile'
import Logout from './Layout/Login/Logout'
import config from './config/env_variables'
import { ContactRecord } from './Apps/ContactCenter/Models'
import Notification from './Layout/Notification/Notification'
import Offline from './Apps/ContactCenter/Layout/Offline/Offline'
import ContactCenter from './Apps/ContactCenter/Modules/ContactCenter'
import useOnLoad from './hooks/useOnLoad'
import { IncomingCall } from './Apps/ContactCenter/Layout/IncomingCall'
import { MissedCall } from './Apps/ContactCenter/Layout/MissedCall'
import { FailurePopup } from './Apps/ContactCenter/Layout/FailurePopup'
import RemoveParticipant from './Layout/Notification/RemoveParticipant'
import { fetchAgentFeatureFlag } from './Apps/ContactCenter/Api/featureFlag'
import { updateLastAgentStatusOnVpn, setReleaseNotes } from './redux/actions/preferencesActions'
import { setAgentDetails, setAgentFeatureTags, setAuthFail } from './redux/actions/contactCenterActions'
import { changeVpnStatus } from './redux/actions/preferencesActions'
import { getReleaseNotes } from './Apps/ContactCenter/Api/api'
import axios from 'axios';
import localization from './Apps/Chat/Components/lang/localization'
import { RootState } from './redux/reducers/rootReducer'
const domain = window.location.host.includes('dev')
const AGENT_STATE_VALUES = ['Pending', 'CallingCustomer', 'Missed', 'Busy', 'AfterCallWork', 'Offline', 'PendingBusy'];
const VPN_ERROR_STATUS_CODE = 550;
console.log('current_URL', window.location.host, domain)

const ThemeContainer = () => {
  const location = useLocation()
  const showCCPLoader = ['/chat', '/history']
  const [networkMode, setNetworkMode] = useState('')
  const { darkMode: globalDarkMode, language, isVpnConnected, lastAgentStatusWhenVpnIsOn, isAnyActiveContactAvailable, releaseNotes: releaseNoteState, showLoaderOnOAC } = useSelector((state: RootState) => state.preferences)
  const { currentConversation, notification, alertData, CCPStatus, incomingCallData, missedCallData, recordingDisabled, participantLeft, agentDetails, isAuthFail } = useSelector((state: RootState) => state.contactCenter)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const [releaseNoteOpen, releaseNotePopUp] = useState(false)
  const V3Theme = globalDarkMode ? darkTheme : lightTheme
  const V2Theme = globalDarkMode ? 'dark' : 'light'
  const agentDesktopClass = globalDarkMode ? 'agent-desktop agent-desktop-scroll-dark' : 'agent-desktop agent-desktop-scroll-light'
  const { ldap = '' } = agentDetails
  let previousStatusCode: any = '';

  React.useEffect(() => {
    const body: any = document.getElementsByTagName('BODY')[0]
    body.className = globalDarkMode ? 'agent-desktop-scroll-dark' : 'agent-desktop-scroll-light'
    return () => {
      body.className = ''
    }
  }, [agentDesktopClass, globalDarkMode])

  React.useEffect(() => {
    window.addEventListener('online', () => {
      updateOnlineStatus()
    })
    window.addEventListener('offline', () => {
      updateOnlineStatus()
    })
    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [])
  const updateOnlineStatus = () => {
    setNetworkMode(navigator.onLine ? 'Online' : 'Offline')
  }
  React.useEffect(() => {
    const fetchFeatureFlag = async () => {
      try {
        const response: any = await fetchAgentFeatureFlag(ldap);
        const { data: { json: { User: { Tags = {}, SiteLocation = '' } = {} } = {} } = {} } = response
        dispatch(setAgentDetails({ ...agentDetails, agentSiteLocation: SiteLocation }))
        const agentFeatureTags = Tags;
        dispatch(setAgentFeatureTags(agentFeatureTags))
        console.log("Agent level feature flag = ", agentFeatureTags);
        getReleaseNotesAndSetLocalStorage();
      }
      catch (error) {
        console.log(error);
      }
    }
    if (ldap)
      fetchFeatureFlag()
  }, [ldap])

  const getReleaseNotesAndSetLocalStorage = async () => {
    try {
      const notesResponse: any = await getReleaseNotes();
      if (notesResponse?.data?.success && notesResponse?.data?.data?.length) {
        //fetch o th index as response is already sorted
        const releaseNote = notesResponse.data.data[0];
        dispatch(setReleaseNotes(releaseNote))
        const currentVersion = localStorage.getItem("RELEASE_VERSION")
        if (!(currentVersion && currentVersion === releaseNote.version)) {
          localStorage.setItem("RELEASE_VERSION", releaseNote.version);
          releaseNotePopUp(true)
        }
      }
    } catch (e) {
      console.log("Error Occured in Release Notes")
    }

  }

  React.useEffect(() => {
    const vpnInterval = setInterval(checkVpnStatus, Number(config.VPN_SET_INTERVAL_TIME))
    return () => {
      clearInterval(vpnInterval);
    }
  }, [])


  React.useEffect(() => {
    if (isVpnConnected && lastAgentStatusWhenVpnIsOn && CCPStatus.ccpState === "PC Recovery") {
      console.log(`VPN_DISCONNECT_CHECK - VPN reconnected and setting status set to ${lastAgentStatusWhenVpnIsOn} from ${CCPStatus.ccpState}`)
      ContactCenter.setStatus(lastAgentStatusWhenVpnIsOn)
    }
    if (!isVpnConnected ) {
      console.log(`VPN_DISCONNECT_CHECK -( VPN Event ) As there is no Active contact, setting status to PC Recovery due to VPN Disconnect`)
      ContactCenter.setStatus("PC Recovery")
    }
    setIsOpen(!isVpnConnected);
  }, [isVpnConnected])

  React.useEffect(() => {
    console.log(`VPN_DISCONNECT_CHECK -State Value Received as ${CCPStatus.ccpState} & VPN Status is ${isVpnConnected}`)
    if (isVpnConnected === true && !AGENT_STATE_VALUES.includes(CCPStatus.ccpState)) {
      console.log(`VPN_DISCONNECT_CHECK - redux status value set to ${CCPStatus.ccpState}`)
      dispatch(updateLastAgentStatusOnVpn(CCPStatus.ccpState))
    }
  }, [CCPStatus.ccpState])

  const setStatus = (code: any) => {
    console.log(`VPN_DISCONNECT_CHECK - Code: ${code}, previousStatusCode : ${previousStatusCode}`)
    if (code === VPN_ERROR_STATUS_CODE) {
      if (previousStatusCode !== VPN_ERROR_STATUS_CODE) {
        console.log(`VPN_DISCONNECT_CHECK - ${VPN_ERROR_STATUS_CODE} Condition Block Executed`)
        previousStatusCode = code;
        dispatch(changeVpnStatus(false));
        ContactCenter.endCall();
      }
    } else {
      if (previousStatusCode === VPN_ERROR_STATUS_CODE) {
        console.log(`VPN_DISCONNECT_CHECK - ENABLEMENT Condition Block Executed`)
        previousStatusCode = code;
        dispatch(changeVpnStatus(true))
      }
    }
  }

  const checkVpnStatus = async () => {
    const url = '/favicon.ico?millisec=' + new Date().valueOf()
    axios.get(url)
      .then((success) => {
        console.log(`VPN_DISCONNECT_CHECK - VPN is Connected`)
        setStatus(success.status)
      }).catch((error) => {
        const errorCode = error?.response?.status ? error.response.status : 500;
        console.log(`VPN_DISCONNECT_CHECK - First time Catch Block error Code : ${errorCode}`)
        if (errorCode === VPN_ERROR_STATUS_CODE) {
          setTimeout(async () => {
            axios.get(url)
              .catch((nextError: any) => {
                const nextErrorCode = nextError?.response?.status ? nextError.response.status : 500;
                console.log(`VPN_DISCONNECT_CHECK - Second time Catch Block error Code : ${nextErrorCode}`)
                setStatus(nextErrorCode)
              })
          }, Number(config.VPN_SET_TIMEOUT_VALUE))
        } else {
          setStatus(errorCode)
        }
      })
  }

  const dialogDismiss = (action: any, data: any) => {
    return dispatch({ type: action, payload: data })
  }

  const handleAuthFail = () => {
    dispatch(setAuthFail(false))
  }

  return (
    <V3Provider theme={V3Theme} colorScheme='light'>
      <Provider theme={V2Theme}>
        {!CCPStatus.isInit && showCCPLoader.indexOf(location.pathname) >= 0 && (
          <div className='ccp-loader'>
            {' '}
            <ProgressCircle aria-label='Loading…' isIndeterminate />{' '}
          </div>
        )}
        {showLoaderOnOAC && (
          <div className='ccp-loader'>
            {' '}
            <ProgressCircle aria-label='Loading…' isIndeterminate />{' '}
          </div>
        )}
        <Router>
          <div className={agentDesktopClass}>
            <div id='ccpContainer' style={{ display: 'none' }} />
            <SecureRoute path='/(!?chat|call|history)' component={NavigationMenu} />
            <main>
              <Route path='/' exact component={Login} />
              <Route path='/login/callback' exact component={LoginCallback} />
              <SecureRoute path='/chat' component={Chat} />
              <SecureRoute path='/call' component={Phone} />
              <SecureRoute path='/profile' component={Profile} />
              <SecureRoute path='/history' component={AllConversations} />
              <Route path='/logout' component={Logout} />
            </main>
          </div>
          {notification.show && <Notification notification={notification} currentConversation={currentConversation} language={language} />}
          <DialogContainer onDismiss={() => dialogDismiss("SETOFFLINEINDICATOR", { isOpen: false })} type='modal'>
            {alertData?.isOpen && <Offline language={language} alertData={alertData} />}
          </DialogContainer>
          <DialogContainer onDismiss={() => dialogDismiss("INCOMING_CALL", { openPopup: "no" })} type='modal'>
            {incomingCallData?.openPopup === "yes" && <IncomingCall darkMode={globalDarkMode} incomingCallData={incomingCallData} />}
          </DialogContainer>
          {
            participantLeft && <RemoveParticipant lang={language} />
          }
          <DialogContainer onDismiss={() => dialogDismiss("MISSED_CALL", { isMissed: false })} type='modal'>
            {missedCallData?.isMissed && <MissedCall darkMode={globalDarkMode} missedCallData={missedCallData} />}
          </DialogContainer>
          <DialogContainer onDismiss={() => dialogDismiss("RECORDING_DISABLED", { ...recordingDisabled, failed: false })} type='modal'>
            {recordingDisabled.failed && <FailurePopup language={language} darkMode={globalDarkMode} />}
          </DialogContainer>
          {<DialogContainer onDismiss={() => setIsOpen(false)} type='modal'>
            {isOpen && <AlertDialog
              title="Warning"
              variant="error"
              primaryActionLabel="Close">
              {localization[language].NOTIFICATIONS.VPN_DISCONNECTED_MESSAGE}
            </AlertDialog>}
          </DialogContainer>
          }
          {<DialogContainer onDismiss={() => releaseNotePopUp(false)} type='modal'>
            {releaseNoteOpen && <AlertDialog
              title={localization[language].NOTIFICATIONS.RELEASE_NOTES + ' v'+releaseNoteState.version}
              primaryActionLabel="Close">
              {localization[language].NOTIFICATIONS.WHAT_NEW}
              {releaseNoteState[`releaseNotes_${language}`].length ?
                releaseNoteState[`releaseNotes_${language}`].map((notes) => {
                  return <li>{notes}</li>
                }) : ""}
            </AlertDialog>}
          </DialogContainer>
          }
          <DialogContainer onDismiss={() => handleAuthFail()} type='modal'>
            {isAuthFail && <AlertDialog
              title={localization[language].NOTIFICATIONS.AUTHENTICATION_ERROR_TITLE}
              variant="error"
              primaryActionLabel="OK">
              {localization[language].NOTIFICATIONS.AUTHENTICATION_ERROR_BODY}
            </AlertDialog>}
          </DialogContainer>
          {!notification.show && networkMode === 'Offline' && <NetworkNotification language={language} networkMode={networkMode} setNetworkMode={() => setNetworkMode('')} />}
        </Router>
      </Provider>
    </V3Provider>
  )
}

const oktaAuth = new OktaAuth(oktaconfig.oidc)

const App = () => {
  const history = useHistory()
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
    window.location.reload()
  }
  useOnLoad(oktaAuth)
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ReduxProvider store={store}>
        <ThemeContainer />
      </ReduxProvider>
    </Security>
  )
}

export default App
